import {customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../../flash/flash-service";
import {WorkflowService} from "../../workflow/workflow-service";
import "./pal-account.less"

@inject(Client, DialogService, FlashService, WorkflowService)
@customElement('sio-accounting-pal-account')
export class PalAccount {

    /** @type {Client} */
    client;
    /** @type {DialogService} */
    dialogService;
    /** @type {FlashService} */
    flash;
    /** @type {Form} */
    form;

    pdfConfig = {
        label: 'action.pdf-download',
        buttonClass: 'btn btn-primary',
        disabled: '',
    }

    showConfig = {
        label: 'action.show',
        buttonClass: 'btn btn-primary',
        disabled: ''
    }

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
    }

    async submit(print) {
        this.pdfConfig.disabled = 'disabled'
        this.showConfig.disabled = 'disabled'
        this.data = null;
        this.table = [];

        const table = []
        const data = await this.workflowService.trigger(
            `accounting/pal-account${print ? '-print' : ''}`,
            null,
            this.form.formService.getValue()
        ).then()

        this.data = data[0].pal;
        this.pdfConfig.disabled = ''
        this.showConfig.disabled = ''

        if (data[0].url) {
            window.location.href = data[0].url;
        }

        (data[0].pal.items ?? []).forEach((value, key) => {
            table.push({...value, mainId: `${key}-main`, indent: 0});

            (value.subItems ?? []).forEach((subItem, index) => {
                table.push({...subItem, mainId: `${index}-${key}-main-subItem`, indent: 1});

                (subItem.positions ?? []).forEach(position => {
                    table.push({...position, id: `${index}-${key}-main-subItem`, indent: 2})
                });
            });

            (value.positions ?? []).forEach(position => {
                table.push({...position, id: `${key}-main`, indent: 1})
            })
        })

        console.error({table})

        this.table = table
    }

    open(title, btnIndex) {
        const trigger = document.getElementById(btnIndex),
            closed = trigger.getAttribute("aria-expanded") === "false",
            add = closed ? "visible" : "hiderow",
            remove = !closed ? "visible" : "hiderow"

        document.querySelectorAll(`[id='${title.mainId}']`).forEach(elem => {
            elem.classList.add(add)
            elem.classList.remove(remove)
        })

        trigger.setAttribute("aria-expanded", closed ? "true" : "false")
    }
}
