import {customElement, inject} from "aurelia-framework";
import {WorkflowService} from "../../workflow/workflow-service";
import "./balance-sheet.less"

@inject(WorkflowService)
@customElement('sio-accounting-balance-sheet')
export class BalanceSheet {
    /** @type {WorkflowService} */
    workflowService;
    /** @type {Form} */
    form;

    pdfConfig = {
        label: 'action.pdf-download',
        buttonClass: 'btn btn-primary',
        disabled: '',
    }

    showConfig = {
        label: 'action.show',
        buttonClass: 'btn btn-primary',
        disabled: ''
    }

    constructor(workflowService) {
        this.workflowService = workflowService;
        this.submit = this.submit.bind(this);
    }

    async submit(print) {
        this.pdfConfig.disabled = 'disabled'
        this.showConfig.disabled = 'disabled'
        this.data = null;

        const data = await this.workflowService.trigger(
            `accounting/balance-sheet${print ? '-print' : ''}`,
            null,
            this.form.formService.getValue()
        ).then();

        this.data = data[0].sheet;
        this.pdfConfig.disabled = ''
        this.showConfig.disabled = ''

        if (data[0].url) {
            window.location.href = data[0].url;
        }

        this.table = {
            ...this.data,
            assets: [].concat(
                ...Object.entries(data[0].sheet.assets)
                    .map(([key, item]) => this.#processItems(item, `${key}-main-assets`))
            ),
            liabilities: [].concat(
                ...Object.entries(data[0].sheet.liabilities)
                    .map(([key, item]) => this.#processItems(item, `${key}-main-liabilities`))
            ),
        }
    }

    open(event) {
        const trigger = document.getElementById(event.detail.btnIndex),
            closed = trigger.getAttribute("aria-expanded") === "false",
            add = closed ? "visible" : "hiderow",
            remove = !closed ? "visible" : "hiderow"

        document.querySelectorAll(`[id='${event.detail.mainId}']`).forEach(elem => {
            elem.classList.add(add)
            elem.classList.remove(remove)
        })

        trigger.setAttribute("aria-expanded", closed ? "true" : "false")
    }

    #processItems(item, mainId, indent = 0) {
        return [{...item, mainId: mainId ?? item.id, indent}]
            .concat((item.positions ?? []).map(position => ({...position, id: mainId ?? item.id, indent: 1 + indent})))
            .concat(
                ...(item.subItems ?? []).map((subItem, index) => this.#processItems(subItem, `${mainId}-${index}`, 1 + indent))
            );
    }
}
