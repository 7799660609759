import {bindable, customElement, inject} from "aurelia-framework";
import {WorkflowService} from "../../workflow/workflow-service";

import "./bwa.less";

@inject(WorkflowService)
@customElement('sio-accounting-bwa')
export class Bwa {
    /** @type{Form} */
    form;
    /** @type{WorkflowService} */
    workflowService;

    @bindable() bwa;
    @bindable positions = null;

    pdfConfig = {
        label: 'PDF-Download',
        buttonClass: 'btn btn-primary'
    }
    excelConfig = {
        label: 'Excel-Download',
        buttonClass: 'btn btn-primary'
    }
    submitConfig = {
        label: 'Anzeigen',
        buttonClass: 'btn btn-primary'
    }

    constructor(workflowService) {
        this.workflowService = workflowService;
        this.submit = this.submit.bind(this);
    }

    async bind() {
        this.positions = null;
        this.title = 'BWA-Position';
        this.headers = null;
    }

    submit(type) {
        this.excelConfig.diabled = this.pdfConfig.disabled = this.submitConfig.disabled = true;
        this.data = null;
        this.dummyDataForTable = [];
        return this.workflowService
            .trigger(`accounting/generate-bwa${type ? "-" + type : ""}`, [this.bwa], this.form.formService.getValue())
            .then(data => {
                this.data = data[0].bwa;

                if (data[0].url) {
                    window.location.href = data[0].url;
                }

                 for (const [key, value] of Object.entries(this.data.items)) {
                    console.log(`${key}: ${value}`);
                    this.dummyDataForTable.push({...value,mainId:key})
                    value.positions?.map(position =>{
                        // value.title.replace(/[.,/\s]/g, '')
                        this.dummyDataForTable.push({...position,id:key})
                    })
                }

                return dummyDataForTable;
            }).finally(() => {
                this.excelConfig.diabled = this.pdfConfig.disabled = this.submitConfig.disabled = false;
            })
    }

    open(title,btnIndex) {
        let id = title.mainId
        var elms = document.querySelectorAll(`[id='${id}']`);
        var theButton = document.getElementById(btnIndex);
        if (theButton.getAttribute("aria-expanded") === "false"){

            for(var i = 0; i < elms.length; i++) {
                elms[i].classList.add('visible');
                elms[i].classList.remove('hiderow');

            }
            theButton.setAttribute("aria-expanded", "true");
        }else{
            for(var i = 0; i < elms.length; i++) {
                elms[i].classList.add('hiderow');
                elms[i].classList.remove('visible');
            }
            theButton.setAttribute("aria-expanded", "false");
        }
    }
}
